import {request} from './index.js'
// 视频列表
export function vodeUer(userId){
    return request({
        url:'/business/shortVideo/redis/random/'+userId
       
    })
  }

export function vodeList(userId,start,size){
    return request({
        url:'/business/shortVideo/redis/'+userId+'/'+start+'/'+size
    })
  }
  
  