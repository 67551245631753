import {request} from './index.js'

// 查询用户
export function getUser(phone){
  return request({
      url:'/business/user/findUserByPhone',
      params: {
         phone
       },
  })
}

// 添加用户
export function addUser(add){
  return request({
      url:'/business/user/addUser',
      method:'post',
      data:add
  })
}


// 添加评论
export function addComment(dataJson){
    return request({
        url:'/business/comment/add',
        method:'post',
        data:dataJson
    })
}

// 查看评论
export function getComment(fromId){
  return request({
      url:'/business/comment/getCommentList',
      params:{
        fromId
      }
  })
}

// 点赞
export function addLink(videoId,userId){
  return request({
      url:'/business/videoLike/addLike',
      params:{
        videoId,
        userId
      }
  })
}

// 查询点赞
export function getLikeNum(videoId){
  return request({
      url:'/business/videoLike/getLikeNum',
      params:{
        videoId
      }
  })
}
