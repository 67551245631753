<template>
  <div class="video-list">
  
    <swiper :options="swiperOption">
      <!-- <button @click.="maop" style="z-index:9999">阻止冒泡</button> -->

      <!-- 幻灯内容 -->
      <!-- 循环输出 -->
      <swiper-slide v-for="(item, index) in dataList" :key="index">
        <div>
          <!-- ref：节点 -->
          <videos ref="videos" :videoList="item" :index="index" ></videos>
        </div>
        <div class="infobar-warp">
          <info-bar :desc="item"></info-bar>
        </div>
        <!-- <div class="right-warp" @click="maop"> -->
          <!-- 接收RightBar.vue穿过来的方法 -->
          <!-- <right-bar @changeCom="showCom" :userId="userId"></right-bar> -->
        <!-- </div> -->
      </swiper-slide>
      <!-- <swiper-slide>slide2</swiper-slide> 
      <swiper-slide>slide1</swiper-slide>    
      <swiper-slide>slide2</swiper-slide>  -->
    </swiper>
    <!-- 评论 -->
    <!-- <van-popup
      v-model="commentPop"
      closeable
      :overlay="true"
      class="comment_container"
      position="bottom"
    >
      <div class="comment_box">
        <div class="comment_top">
         <span v-show="this.videoComment.length<0">{{this.videoComment.length}}条评论</span>
          {{this.videoComment.length}}条评论
          <i class="iconfont icon-guanbi1 guanbi3" @click="closeComment"></i>
        </div>
        <ul class="comment_ul">
          <div v-if="videoComment.length != 0">
            <transition-group appear>
              <li
                class="comment_li"
                v-for="(item,index) in videoComment"
                :key="index"
                @click="replayUser(item, index, -1)"
              >
                <div class="comment_author_left">
                  <img :src="item.userData.imageUrl" />
                </div>
                <div class="comment_author_right">
                  <div class="comment_author_top">
                    <div class="comment_author_name">
                      {{ item.userData.userName }}
                    </div> -->
                    <!-- 评论点赞 -->
                    <!-- <div
                      class="icon-shoucang1_box"
                      @click.stop="commentLove(item, index, -1)"
                    >
                      <div
                        class="icon_right_change"
                        :class="item.love_comment ? 'love_active' : ''"
                      >
                        <i class="iconfont icon-shoucang icon-shoucang1"></i>
                      </div>
                      <div class="shoucang1_num">{{ item.love_count }}</div>
                    </div>
                  </div>
                  <div class="comment_author_text">
                    {{ item.commentContent
                    }}<span>{{ item.createTime | timeFil }}</span>
                  </div>
                </div>
                <div class="clear"></div>
                <div class="comment_replay_box">
                  <transition-group appear> -->
                    <!-- 二级回复 -->
                    <!-- <div
                      class="comment_replay_li"
                      v-for="(item2, index2) in item.children"
                      :key="index2"
                      @click.stop="replayUser(item2, index, index2)"
                    >
                      <div class="comment_replay_left">
                        <img :src="item2.userData.imageUrl" />
                      </div>
                      <div class="comment_replay_right">
                        <div class="comment_replay_top">
                          <div class="comment_replay_name">
                            @{{ item2.userData.userName}}
                          </div>
                          <div
                            class="icon-shoucang1_box"
                            @click.stop="commentLove(item2, index, index2)"
                          > -->
                            <!-- <div
                              class="icon_right_change"
                              :class="item2.love_comment ? 'love_active' : ''"
                            >
                              <i
                                class="iconfont icon-shoucang icon-shoucang1"
                              ></i>
                            </div>  -->
                            <!-- <div class="shoucang1_num"> -->
                              <!-- {{ item2.love_count }} -->
                            <!-- </div>
                          </div> -->
                        <!-- </div> -->
                        <!-- 二级回复的内容 -->
                        <!-- <div class="comment_replay_text"> -->
                          <!--v-if="item.userId != item2.subCommentId && item.userId != item2.userId"-->
                          <!-- <span>回复 {{ item2.userData.userName }}：</span> -->
                          <!-- {{ item2.commentContent }}
                          <span>{{ item2.createTime | timeFil }}</span>
                        </div>
                       
                      </div>
                      <div class="clear"></div>
                    </div>
                  </transition-group>
                </div>
              </li>
            </transition-group> -->
          <!-- </div>
          <div class="no_message" v-if="videoComment.length == 0">
            <i class="iconfont iconfont_style icon-zanwupinglun"></i>
            <div class="no_message_tips">暂无评论</div>
          </div>
        </ul>
      </div>
    </van-popup> -->
    <!--留言输入-->
    <!-- <div class="comment_input_box_hover"></div>
    <div class="comment_input_box" v-show="commentPop"> -->
      <!--<form action="#" class="comment_form">-->
      <!-- <input
        :placeholder="commentPlaceholder"
        class="comment_input"
        v-model="comment_text"
        ref="content"
        @keyup.enter="checkComment"
      /> -->
      <!--</form>-->
      <!-- <div class="comment_input_right" @click="checkComment">
        <i
          class="iconfont icon-fasong comment_i"
          :class="canSend ? 'comment_i_active' : ''"
        ></i>
      </div>
    </div> -->
  </div>
</template>

<script>
//导入swiper组件'
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

// vant组件
//  import Vue from "vue";
import { Swipe, SwipeItem, Toast, Popup } from "vant";
// Vue.use(Swipe, Toast).use(SwipeItem);

//导入播放组件
import Videos from "../../components/index/Videos.vue";
import InfoBar from "../../components/index/InfoBar";
import RightBar from "../../components/index/RightBar";
import PopComment from "../../components/index/PopComment.vue";

// 请求接口
import {
  getComment,
  addComment,
  getUser,
  addUser,
  addLink,
} from "../../http/video/request";
import {vodeList,vodeUer} from '../../http/video/home'
export default {
  name: "VideoList",
  components: {
    //注册swiper组件
    Swiper,
    SwiperSlide,
    Videos,
    InfoBar,
    RightBar,
    PopComment,
    [Popup.name]: Popup,
    [Swipe.name]: Swipe,
    [Toast.name]: Toast,
    [SwipeItem.name]: SwipeItem,
  },

  // directives:{
  //   swiper:directive
  // },
  data() {
    return {
      show: true,
      showComment: false,
      comment_text: "",
      page: 1,
      num:1,
      phone:'999',
      swiperOption: {
        //垂直方向滑动
        direction: "vertical",
        grabCursor: true,
        setWrapperSize: true,
        autoHeight: true, //自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化
        slidesPerView: 1,
        mousewheel: true,
        mousewheelControl: true,
        // 高度设置，占满设备高度
        height: window.innerHeight,
        resistanceRatio: 0,
        observeParents: true,

        on: {
          //详见：https://www.swiper.com.cn/api/event/226.html
          tap: () => {
            // console.log('1111');
            this.playAction(this.page - 1);
          },
          //上滑
          //详见：https://www.swiper.com.cn/api/event/290.html
          slideNextTransitionStart: () => {
            // alert('开始切换');
            this.page += 1;
            this.nextVideo(this.page - 1);
            // console.log(this.page);
            // console.log(this.dataList);
            // console.log(this.num)
            let numPage=this.num+this.page
            if (this.page == this.dataList.length) {
              // console.log("触底了");
              // console.log(numPage)
              this.topCal(numPage);
            }
            // console.log(this.dataList[this.page - 1]);
          },
          slidePrevTransitionStart: () => {
            if (this.page > 1) {
              this.page -= 1;
              this.preVideo(this.page - 1);
              console.log(this.page);
            }
          },
        },
      },
      dataList: [],

      // 评论
      commentPop: false, //是否展示评论弹框
      // 评论相关
      comment_text: "", //评论输入内容
      canSend: false, //是否可以发送
      videoComment: [],
      commentPlaceholder: "留下你精彩的评论吧", //评论Placeholder
      replayUserData: "",
      to_comment_id: "",
    };
  },
  mounted(){
  
  },
  created() {
    //取用户号码
    console.log(this.$route.query)
     this.userInfo={
         phone:this.$route.query.phone, //手机号
         avatar:this.$route.query.avatar, //图片
         nickname:this.$route.query.nickname, //昵称   
      }

    

     // 调用用户判断
     this.getPhonet(this.userInfo.phone);
     console.log(this.userInfo)
  
    // this.userInfo = JSON.parse(localStorage.getItem("h5_userInfo"));
    // console.log(this.userInfo);
    
    // this.getData(this.phone);
    //  this.getAdd()
  },
  methods: {

    playAction(index) {
      this.$refs.videos[index].playOrStop();
    },
    //上滑
    nextVideo(index) {
      //通过DOM元素来获取子组件的方法
      //上一个视频停止
      this.$refs.videos[index - 1].stop();
      //下一个视频开始
      this.$refs.videos[index].play();
    },
    //下滑
    preVideo(index) {
      this.$refs.videos[index +1].stop();
      this.$refs.videos[index].play();
    },
    //弹出评论(弹窗)
    showCom() {
      this.commentPop = true;
      this.videoComment = [];
      this.getComment();
    },
    close() {
      this.showComment = false;
    },
    //  请求数据
     getData(phone) {
        vodeUer(phone).then(res=>{
            // console.log(res)
            if(res.data.code==200){
              this.getvodes(phone)
            }
        }).catch(err=>{
          console.log(err)
        })

    },
  // 请求视频列表
   getvodes(phone_list){
      vodeList(phone_list,this.num,5).then(res=>{
        // console.log(res)
        this.dataList.push(...res.data.rows)
      }).catch(err=>[
        console.log(err)
      ])
   },


    // 上拉加载
    async topCal(numPage) {
    vodeList(this.userInfo.phone,numPage,5).then(res=>{
        // console.log(res)
        this.dataList= this.dataList.concat(...res.data.rows)
        // console.log(this.dataList)
      }).catch(err=>[
        console.log(err)
      ])
    },

    // 查询用户是否已经存在
    async getPhonet(phone) {
      // 先判读号码是否符合标准
      // var re = /^1\d{10}$/;
      // re.test(phone)
      console.log(phone)
      if (phone) {
        await getUser(phone)
          .then((res) => {
            if (res.data.code == 500) {
              console.log("没有该用户");
              
              this.getAdd();
            } else {
              console.log("有该用户", res.data.data.userId);
              // 调用随机列表接口，将用户id传过去
              this.getData(phone)
              Toast('有该用户'+phone);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        console.log("号码不符合规范");
      }
      console.log(this.userId);
    },

    // 添加用户
    getAdd() {
      let phone = {
        homePhoneNumber:this.userInfo.phone, //手机号
        imageUrl: this.userInfo.avatar, //图片
        userName: this.userInfo.nickname, //昵称
      };
      console.log(phone);
      addUser(phone).then((res) => {
        console.log(res.data);
        this.getData(this.userInfo.phone)
         Toast('新增成功'+this.userInfo.phone);
        // this.userId = res.data.data;
      });

    },

    // 提交评论请求
    addComment(content) {
      addComment(content).then((res) => {
        console.log(res);
        this.getComment()
      });
      // console.log(content);
    },

    //  评论
    //获取评论
   async getComment() {
      // let data = [];
     await getComment(2).then((res) => {
         this.videoComment=res.data.data
          // console.log(res.data.data);
          // for(let i=0;i<res.data.data.length;i++){
          //   console.log(res.data.data[i].userData.imageUrl)
          // }
        //获取评论数据
        this.videoComment = [...this.videoComment, ...res.data.data];
        let to_comment_id = this.to_comment_id;
        if (to_comment_id != 0) {
          //从评论列表进来回复
          this.getCommentDetail(to_comment_id);
        }
      });
    },
    //获取单个评论
    getCommentDetail(to_comment_id) {
      let obj = {
        action: "show_comment_info",
        comment_id: to_comment_id,
      };
      // 遍历评论
      setTimeout(() => {
        let index;
        let item = res.data;
        this.videoComment.map((v, i) => {
          v.children.map((v2, i2) => {
            if (v2.commentId == to_comment_id) {
              index = i;
            }
          });
        });
        setTimeout(() => {
          //对评论重新排序，存在to_comment_id的时候排至第一个
          let firstComment = this.videoComment.splice(index, 1);
          this.videoComment.unshift(firstComment[0]);
          //重设回复人
          item.index = 0;
          item.index2 = 0;
          this.replayUserData = item; //将回复人的信息存起来
          this.commentPlaceholder = `回复 @${item.userInfo.nickname}：`;
          this.$refs.content.focus();
        }, 10);
      }, 500);
    },
    //检测评论内容
    checkComment() {
      if (this.comment_text == "") {
        Toast("评论内容不能为空");
      } else {
        let fromId = 2,
          subCommentId = 0,
          content = this.comment_text
        // 如果不为空的时候代表拿到了@回复人的信息
        console.log(this.replayUserData);
        if (this.replayUserData != "") {
          content = content; //评论内容
          fromId = this.replayUserData.fromId; //视频id
          subCommentId = this.replayUserData.commentId; //二级评论要拿的id（一级评论的id）
          
        }
        this.sendComment(fromId, subCommentId, content);
        // 调用评论
      }
    },
    //发送评论
    sendComment(fromId, subCommentId, content) {
      this.comment_text = "";
      this.isSending = true;
      let comment = {
        subCommentId: subCommentId,
        commentContent: content,
        userId: this.userId,
        fromId: fromId,
        type: 1, //固定
        platform: 4, //固定
      };

      if (this.replayUserData == "") {
        //回复作品
        // this.videoComment.splice(0, 0,this.addComment(comment))
        // this.addComment(comment);
      } else {
        let index = this.replayUserData.index;
        let index2 = this.replayUserData.index2;
        console.log(index2)  //index2: -1代表从一级评论， 0代表二级
        if (this.replayUserData.index2 == -1) {
          //回复一级人
          // this.videoComment[index].children.splice(0, 0,this.addComment(comment))
          this.addComment(comment);
          this.commentPlaceholder = "留下你精彩的评论吧";
        } else{
          //回复二级人
            if(this.replayUserData.children.userId !==null || this.replayUserData.children.userId !== '' ){
                   this.addComment(comment);     
            }else{
              console.log('id为空无法添加')
            }
            
           setTimeout(()=>{
          //  console.log(this.videoComment[index].children[index2])
             if(this.videoComment[index].children[index2].length > 0){
              //  this.videoComment[index].children[index2]
              console.log('大于')
             }else{
               console.log('小于')
             }

            },1000)
            
        
        }
      }
      this.replayUserData = "";
      this.isSending = false;
    },
    //评论点赞
    commentLove(item, index, index2) {
      let comment_id = item.comment_id,
        user_id = item.user_id,
        love_comment = item.love_comment,
        love_flag = 0; //0:取消点赞；1：点赞
      if (love_comment) {
        //取消点赞
        love_flag = 0;
      } else {
        //添加点赞
        love_flag = 1;
      }
      //setTimeout模拟Ajax请求
      setTimeout(() => {
        if (index2 == -1) {
          //点赞一级评论
          this.videoComment[index].love_comment =
            !this.videoComment[index].love_comment;
          if (love_flag == 1) {
            this.videoComment[index].love_count++;
          } else {
            this.videoComment[index].love_count--;
          }
        } else {
          //点赞二级评论
          this.videoComment[index].child_comment[index2].love_comment =
            !this.videoComment[index].child_comment[index2].love_comment;
          if (love_flag == 1) {
            this.videoComment[index].child_comment[index2].love_count++;
          } else {
            this.videoComment[index].child_comment[index2].love_count--;
          }
        }
      }, 500);
    },
    //点击回复
    replayUser(item, index, index2) {
      item.index = index;
      item.index2 = index2;
      this.replayUserData = item;
      this.commentPlaceholder = `回复 @${item.userData.userName}：`;
      this.$refs.content.focus();
    },
    //弹出评论窗口
    // changeComment() {
    //     this.commentPop = true;
    //     this.videoComment = [];
    //     this.getComment()
    // },
    //关闭评论弹窗
    closeComment() {
      this.commentPop = false;
      this.commentPlaceholder = "留下你精彩的评论吧";
      this.replayUserData = "";
    },
  },

  watch: {
    //监听输入变化
    comment_text(newV, oldV) {
      // console.log(newV)
      newV == "" ? (this.canSend = false) : (this.canSend = true);
    },
  },
  filters: {
    timeFil(tiem) {
      // console.log(tiem)
      return tiem.substr(5);
    }
    
  },
};
</script>

<style scoped>
.video-list {
  height: 100vh;
  background: #000000;
}
.swiper-container {
  height: 100%;
  position: relative;
}
.infobar-warp {
  position: absolute;
  bottom: 55px;
  left: 0;
}
.right-warp {
  position: absolute;
  bottom: 50px;
  right: 10px;
}
/* 评论 */
.up-enter-active,
.up-leave-active {
  transition: all 0.5s;
}
.up-enter, .up-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 1;
  transform: translateY(100%);
}

.comment-warp {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 12rem;
  width: 100%;
  background: white;
  border-top-left-radius: 0.213333rem;
  border-top-right-radius: 0.213333rem;
  z-index: 999;
  padding: 0.213333rem 0.213333rem;
  box-sizing: border-box;
}
.comment-top {
  display: flex;
  align-items: center;
}
.number {
  flex-grow: 1;
  text-align: center;
  font-size: 0.4rem;
}
.close {
  padding-right: 0.213333rem;
  font-size: 0.613333rem;
  color: #666;
}
.comment-body {
  max-width: 12rem;
  max-height: 9.36rem;
  overflow-y: scroll;
}
/* 隐藏滚动条 */
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.comment-item {
  display: flex;
  margin-bottom: 0.266667rem;
}
.user-pic {
  width: 2.666667rem;
}
.user-pic img {
  height: 0.88rem;
  width: 0.88rem;
  border-radius: 50%;
}
.item-info {
  margin-left: 0.213333rem;
  display: flex;
  flex: 1 1 auto;
}
.reply {
  width: 87%;
}
.reply-des {
  line-height: 24px;
  font-size: 0.346667rem;
}
.reply .name {
  color: #666;
  font-size: 0.346667rem;
  margin-bottom: 0.053333rem;
}
.reply .time {
  color: #666;
}
.zan {
  text-align: center;
  margin-left: 0.4rem;
}
.zan .iconfont {
  font-size: 0.533333rem;
}
.zan p {
  color: #cccccc;
  font-size: 12px;
}

.reply-input {
  width: 100%;
  height: 50px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 0.026667rem solid #eee;
  display: flex;
  align-items: center;
  z-index: 9999;
}
.reply-input input {
  height: 1.2rem;
  width: 100%;
  border: none;
  margin: 0 auto;
  outline: none;
  margin-bottom: 0;
  position: absolute;
  bottom: 0;
  padding: 0 15px;
  font-size: 0.4rem;
}
.reply-input .at1 {
  font-size: 0.8rem;
  color: #cccccc;
  margin-right: 8%;
}
.reply-input .iconfont {
  font-size: 0.8rem;
  color: #cccccc;
  z-index: 9999;
  position: absolute;
  right: 0.266667rem;
}

/*评论样式*/
::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.2);
}

:-moz-placeholder {
  color: rgba(0, 0, 0, 0.2);
}

::-moz-placeholder {
  color: rgba(0, 0, 0, 0.2);
}

:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.2);
}

.comment_container {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 999999;
}

.comment_box {
  padding: 0 15px 52px 15px;
}

.comment_top {
  text-align: center;
  font-size: 12px;
  color: #000;
  line-height: 40px;
}

.guanbi3 {
  float: right;
  font-size: 12px;
  padding: 0 10px;
  position: absolute;
  right: 6px;
}

.comment_li {
  margin-bottom: 20px;
  font-size: 14px;
  text-align: left;
}

.comment_author_left {
  float: left;
}

.comment_author_left img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.comment_author_right {
  margin-left: 46px;
  padding-top: 4px;
}

.comment_author_top {
  position: relative;
}

.comment_author_name {
  margin-bottom: 6px;
  color: #777;
}

.icon-shoucang1_box {
  position: absolute;
  right: 0px;
  top: 0;
  text-align: center;
  color: #777;
}

.comment_author_text {
  color: #555;
  margin-bottom: 10px;
  padding-right: 35px;
}

.comment_replay_box {
  padding-left: 46px;
  box-sizing: border-box;
}

.comment_replay_li {
  margin-bottom: 10px;
}

.comment_replay_left {
  float: left;
}

.comment_replay_left img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.comment_replay_right {
  margin-left: 35px;
  padding-top: 2px;
}

.comment_replay_top {
  position: relative;
  margin-bottom: 6px;
}

.comment_replay_text {
  padding-right: 35px;
  margin-bottom: 10px;
  color: #555;
}

.comment_author_text span,
.comment_replay_text span {
  color: #999;
  font-size: 13px;
  margin-left: 6px;
}

.shoucang1_num {
  text-align: center;
  width: 30px;
  font-size: 12px;
  /* right: -4px; */
  position: relative;
}

.comment_ul {
  height: 400px;
  overflow-y: auto;
}

.comment_input_box {
  position: fixed;
  bottom: 0;
  z-index: 2999;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  background: #fff;
  padding: 10px 15px;
  box-sizing: border-box;
}

/*.comment_form {*/
/**/
/*}*/

.comment_input {
  border: none;
  resize: none;
  width: 80%;
  float: left;
  color: #555;
  caret-color: #f44;
  line-height: 0.666667rem;
  font-size: 0.4rem;
}

.comment_input_right {
  float: right;
}

.comment_i {
  font-size: 22px;
  color: #999;
  transition: 0.3s;
}

.comment_i_active {
  color: #f44;
}
.no_message {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-zanwupinglun {
  font-size: 2.133333rem;
  color: #777;
}
.no_message_tips {
  font-size: 15px;
}

.v-enter,
.v-leave-to {
  opacity: 0;
  transform: translateY(80px);
}

.v-enter-active,
.v-leave-active {
  transition: all 0.5s ease;
}

/*添加进场效果*/
.v-move {
  transition: all 1s ease;
}

.v-leave-active {
  position: absolute;
}

.list-complete-item {
  transition: all 1s;
  display: inline-block;
  margin-right: 10px;
}

.list-complete-enter,
.list-complete-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.list-complete-leave-active {
  position: absolute;
}

.love_active {
  color: #f44;
}

/*评论样式*/
</style>
