<template>
  <div class="right-bar">
  <!--<div class="rightbar-item">
   <div class="avatar-border">
        <img src="../../assets/img/xxx.jpeg">
        <span class="iconfont  icon-jia-tianchong"></span>
      </div>
    </div> -->
    <div class="item-icon" >
      <!-- fabulous_active -->
      <i class="iconfont icon-pinglun" @click.stop="fabulous" :class="flog?'fabulous_active':''"></i>
      <p v-show="linkNum>0">{{linkNum}}</p>
    </div>
    <div class="item-icon" @click.stop="showCom($event)">
      <i class="iconfont icon-liuyan"></i>
      <p>1w</p>
    </div>
    <!-- <div class="item-icon">
      <span class="iconfont icon-fenxiang"></span>
      <p>1w</p>
    </div> -->
    <!-- <div class="rightbar-item">
      <div class="right-music">
        <img src="../../assets/img/xxx.jpeg">
      </div>
    </div> -->
  </div>
</template>

<script>
import {addLink,getLikeNum} from '../../http/video/request'

export default {
  // VideoList.vue传过来的
  props:["showComment","userId"],
  data(){
    return {
      flog:false,
      linkNum:null
    }
  },
  created(){
   console.log(this.userId)
   this.getLikeNum()
  },
  methods:{
    showCom(e){
      e.preventDefault();
      this.$emit('changeCom',this.showComment);
    },
    //点赞
    fabulous(){
      this.flog=!this.flog
      if(this.flog==true){
        console.log('点赞')
         this.addLink()
      }else{
        console.log('取消点赞')
         this.addLink()
      }
    },
    // 添加/取消点赞
   addLink(){
      addLink(1,this.userId).then(res=>{
          console.log('成功',res)
          this.getLikeNum()
        })
    },

    // 查询点赞
     getLikeNum(){
       getLikeNum(1).then(res=>{
        console.log(res.data.data)
        this.linkNum=res.data.data
      })
    }
  }
}
</script>

<style scoped>
/* 点击红心样式 */
 .fabulous_active {
        color: #f44 !important;
        animation: showHeart .5s ease-in-out 0s;
    
  }
    @keyframes showHeart {
        0% {
            color: #f44;
            transform: scale(1);
        }

        25% {
            color: #fff;
            transform: scale(0);
        }

        80% {
            color: #f44;
            transform: scale(1.2);
        }

        100% {
            color: #f44;
            transform: scale(1);
        }
    }
  .right-bar {
    margin-bottom: 200px;
    
  }
  .rightbar-item{
    height:1.866667rem;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
  }
  .avatar-border{
    height:50px;
    width:50px;
    border:1px white solid;
    border-radius: 50%;
    position: relative;
    text-align: center;
  }
  .avatar-border img{
    height:49px;
    width:49px;
    border-radius: 50%;
  }
  .icon-jia-tianchong{
    color: red;
    position:absolute;
    top:35px;
    left:0;
    right:0;
  }
  .item-icon{
    height:60px;
    text-align: center;
    padding-top: .373333rem;
    padding-bottom: .266667rem;
   
  }
  .item-icon .iconfont{
    color: #ffffff;
    font-size: .933333rem;
    margin-bottom: 5px;
    font-size: 42px;
    display: block;
    text-shadow: 0px 0px 10px #9d9d9d;
  }
  .item-icon p{
    color: #ffffff;
    font-size: .373333rem;
    padding-top: .133333rem;
  }
  .right-music{
    height:54px;
    width:54px;
    background: #2e2e2e;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    animation: round 6s 0s linear infinite;
  }
  .right-music img{
    height:30px;
    width:30px;
    border-radius: 50%;
  }

  @keyframes round{
    0%{transform: rotate(0deg);}
    100%{transform: rotate(360deg);}
  }
</style>