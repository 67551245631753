<template>
  <div class="videos">
     <video-player  class="video-player vjs-custom-skin"
          ref="videoPlayer"
            :playsinline="true"
            :options="playerOptions"></video-player> 
  </div>
</template>

<script>
// import 'video.js/dist/video-js.css'
import { videoPlayer } from 'vue-video-player'
export default {
  name:'Videos',
  //子组件接收父组件(VideoList)
  props:['videoList','index'] ,
  data(){
    return{
      playerOptions : {
          autoplay: true, //如果true,浏览器准备好时开始回放。
          muted: true, // 默认情况下将会消除任何音频。
          loop: true, // 导致视频一结束就重新开始。
          preload: 'auto', 
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [
              {
                  src:this.videoList.videoUrl ,  // 路径
                  type: 'video/mp4'  // 类型
              }, 
          ],
          poster:this.videoList.imageUrl,   //预加载图片
          width: document.documentElement.clientWidth,
          notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar:false
      },
      playing:true
    }
},
mounted(){
   this.playOrStop()
},

methods:{
  playOrStop(){
    if(this.playing){
      this.$refs.videoPlayer.player.pause();
      this.playing = false;
    }else{
      this.$refs.videoPlayer.player.play();
      this.playing = true;
    }
  },
  //自动播放第一个视频
  // autoPlayAction(){
  //   if(this.index == 1){
  //     this.playerOptions.autoplay = true;
  //   }
  // },
  play(){
    //重新载入
    this.$refs.videoPlayer.player.load();
    this.$refs.videoPlayer.player.play();
    this.playing = true;
  },
  stop(){
    this.$refs.videoPlayer.player.pause();
    this.playing = false;
  }
},
  components: {
    videoPlayer
  }
}
</script>

<style scroped>
  .videos{
    position: relative;
    /* border: 1px solid green; */
  }
  .video-player{
     height: 100vh;
     display: flex;
     justify-content: center;
     align-items: center;
  }
  .videos .vjs-custom-skin >.video-js .vjs-big-play-button{
    /* background: rgb(255, 255, 255);
    opacity: 0.8; */
    /* background: none; */
    font-size: 30px;
    border-radius: 50%;
    border: none;
    width:60px;
    height:40px;
    position: absolute;
    top:50%;
    left:60%;
    transform: translate(-50%,-50%);
 
   
  }

</style>